import React from "react";

import { Label1, Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";

const Grants = () => {
  return (
    <Page title="Гранты регионального ФРП" path="/frp/grants" parent="frp">
      <Label1>
        На компенсацию затрат на уплату процентов по кредитным договорам
      </Label1>
      <Paragraph1>
        <StyledLink
          href="http://government.ru/dep_news/45217/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Постановлением Правительства РФ от 18.04.2022 №686
        </StyledLink>
        утверждены Правила докапитализации региональных фондов развития
        промышленности из резервного фонда. Выделенные федеральные средства
        через региональные фонды развития промышленности будут направлены на
        предоставление грантов промышленным предприятиям.
      </Paragraph1>
      <Paragraph1>
        С помощью этих грантов предприятия смогут компенсировать до 90% затрат
        на уплату процентов по кредитам, предоставленным им на пополнение
        оборотных средств.
      </Paragraph1>
      <Paragraph1>
        Основной вид деятельности предприятия-заявителя должен соответствовать
        разделу "Обрабатывающие производства" по ОКВЭД (за исключением классов
        10, 11, 12,18, 19, групп 20.53, 20.59, 24.46, подгруппы 20.14.1).
      </Paragraph1>
      <Paragraph1>
        Более подробную информацию о предстоящей грантовой поддержке можно
        получить, обратившись в региональный фонд развития промышленности по
        тел. (8182) 464122доб. 107 (пн-пт с 9:00 до 17:00) или по электронной
        почте <StyledLink href="mailto:frp@cmf29.ru">frp@cmf29.ru</StyledLink>, а также - в{" "}
        <StyledLink
          href="https://dvinaland.ru/gov/iogv/minec/contacts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          управление промышленности министерства экономического развития,
          промышленности и науки Архангельской области
        </StyledLink>
        .
      </Paragraph1>
    </Page>
  );
};

export default Grants;
